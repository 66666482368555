<template>
  <div class="checkbox-with-dropdown">
    <div class="d-flex mb-2 w-100 option">
      <vSelect
        :filterable="true"
        :value="value"
        :class="`w-100 fs-md ${isChecked ? 'checked-option' : ''}`"
        :options="options"
        :clearable="false"
        :placeholder="placeholder"
        :multiple="false"
        :disabled="false"
        @input="(e) =>  updateValue(companyName, e)"
        >
        <template slot="selected-option" slot-scope="option">
          <div class="d-flex align-items-center">
            <img
              :src="`${urlBucket}/${logo}`"
              @error="
                (e) => {
                  e.target.src = `${urlBucket}/${imgOnError}`;
                }
              "
              :width="widthLogo"
              class="ms-3 me-2"
              style="max-height: 55px"
            />
            <span>{{ formatOptionSelected(option) }}</span>
          </div>
        </template>
      </vSelect>

      <div
        :class="`boxes justify-content-center ${isChecked ? 'checked' : ''}`"
        style="min-width: 60px"
        @click="updateValue(informName, !isChecked)"
      >
        <img v-if="isChecked" :src="require('@/assets/check.png')" width="41px" height="33px" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";

export default {
  name: "CheckboxWithDropdown",
  props: {
    logo: [String],
    informName: [String],
    companyName: [String],
    value: [String],
    isChecked: [Boolean],
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    widthLogo: {
      type: String,
      default: "40px",
    },
    imgOnError: {
      type: String,
      default: "",
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {};
  },
  methods: {
    // hideSpinner(id) {
    //   document.getElementById(id).style.display = "none";
    // },
    // displayLogo() {
    //  if (this.companyName.includes("Water")) {

    //  }

    //  if (this.companyName.includes("Council")) {
    //    return
    // }
    // },
    formatOptionSelected(option) {
      return option.label.length >= 47 && window.innerWidth <= 475
        ? option.label.slice(0, 47) + "..."
        : option.label;
    },
    updateValue(attribute, value) {
      // console.log(attribute, value);
      this.$store.commit("formStore/setField", [`${attribute}`, value]);
    },
  },
  computed: {
    ...mapState("formStore", ["urlBucket"]),
  },
};
</script>

<style lang="scss">
.checkbox-with-dropdown {
  .option {
    cursor: pointer;
    height: 55px;
  }

  .boxes {
    border-radius: 6px;
    border: 1px solid white;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .checked {
    border: 1px solid #2ec989;
    background-color: #1e9d68;
    color: white;
  }

  .v-select {
    margin-right: 0.7rem;

    .vs__dropdown-toggle {
      padding-left: 2px;
    }
  }

  .checked-option {
    .vs__dropdown-toggle {
      border: 1px solid #2ec989;
      background-color: #1e9d68;
    }

    .vs__selected {
      color: white;
    }
  }
}
</style>

<template>
  <div class="page-council animate__animated animate__fadeIn pt-4">
    <div class="text-center my-4">
      <img
        :src="require('@/assets/banners/update-utilities.png')"
        class="mb-4 img-fluid"
        style="display: none"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('banner');
          }
        "
      />
      <div id="banner" class="text-center my-5">
        <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
      </div>
    </div>

    <!--  <div v-if="oldCouncil == newCouncil">
      <div class="mb-4">
        <h5 class="fs-la mb-2">
          Your Suppliers <i class="fa-solid fa-circle-info" @click="openInfoTooltip()"></i>
        </h5>
        <Checkbox
          v-if="this.informWaterSewerage"
          :logo="`icons/${newWaterPath}.png`"
          :description="newWater"
          :informName="'informNewWater'"
          :value="informNewWater"
          :subdescription="true"
          :imgOnError="'form/assets/water.svg'"
          :widthLogo="'70px'"
        />

        <Checkbox
          v-if="informCouncilTax"
          :logo="`icons/councils/${newCouncil}.png`"
          :description="newCouncil"
          :informName="'informNewCouncil'"
          :value="informNewCouncil"
          :subdescription="true"
          :imgOnError="'form/assets/hmrc.svg'"
          :widthLogo="'70px'"
        />
      </div>
    </div> -->

    <div class="mb-3">
      <!--v-if="oldCouncil != newCouncil"  -->
      <h5 class="fs-la mb-2">
        Your previous property <i class="fa-solid fa-circle-info" @click="openInfoTooltip()"></i>
      </h5>
      <p class="fs-sm">Close down utilities at {{ moveFromAddress }}</p>

      <CheckboxWithDropdown
        v-if="this.informWaterSewerage"
        :logo="`icons/${oldWater}.png`"
        :informName="'informOldWater'"
        :companyName="'oldWater'"
        :is-checked="informOldWater"
        :value="oldWater"
        :options="waterCompanies"
        :imgOnError="'form/assets/water.svg'"
        :widthLogo="'70px'"
      />

      <CheckboxWithDropdown
        v-if="informCouncilTax"
        :logo="`icons/councils/${oldCouncil}.png`"
        :informName="'informOldCouncil'"
        :companyName="'oldCouncil'"
        :is-checked="informOldCouncil"
        :value="oldCouncil"
        :options="councils"
        :imgOnError="'form/assets/hmrc.svg'"
        :widthLogo="'70px'"
      />

      <!-- <Checkbox
        v-if="this.informWaterSewerage"
        :logo="`icons/${oldWater}.png`"
        :description="oldWater"
        :informName="'informOldWater'"
        :value="informOldWater"
        :subdescription="false"
        :imgOnError="'form/assets/water.svg'"
        :widthLogo="'70px'"
      />

      <Checkbox
        v-if="informCouncilTax"
        :logo="`icons/councils/${oldCouncil}.png`"
        :description="oldCouncil"
        :informName="'informOldCouncil'"
        :value="informOldCouncil"
        :subdescription="false"
        :imgOnError="'form/assets/hmrc.svg'"
        :widthLogo="'70px'"
      /> -->
    </div>

    <div v-if="informEnergy">
      <h5 class="fs-sm">Electricity</h5>

      <div class="input-search-container mb-3">
        <div class="container-search-icon">
          <i v-if="electricitySelected.length == 0" class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          :class="`w-100 fs-md ${electricitySelected.length == 0 ? 'ps-5' : ''}`"
          label="name"
          :clearable="false"
          :value="electricitySelected[0]"
          :options="energySuppliers"
          :placeholder="`Search electricity provider`"
          :multiple="false"
          @option:selected="
            (ev) => selectChange(ev, 'electricitySelected', 'selectedEnergySupplier')
          "
        >
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center py-1">
              <img
                :src="`${urlBucket}/${option.icon}`"
                width="30px"
                class="me-3"
                style="display: none"
                @load="
                  (e) => {
                    e.target.style.display = 'unset';
                    hideSpinner(option.name);
                  }
                "
              />
              <div :id="option.name" class="text-center me-3">
                <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
              </div>
              <p class="mb-0 fs-md">{{ option.name }}</p>
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="d-flex align-items-center">
              <img
                :src="`${urlBucket}/${option.icon}`"
                class="ms-3 me-2"
                style="max-height: 40px"
              />
              <span>{{ option.name }}</span>
            </div>
          </template>
        </vSelect>
      </div>

      <div class="chip-list-wrapper mb-2" v-if="electricitySelected.length > 0">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in electricitySelected"
          :key="index"
          @click="deleteElectricity(index)"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.name }}</p>
        </div>
      </div>
    </div>

    <!-- Energy-->
    <div class="mb-5" v-if="informEnergy">
      <h5 class="fs-sm">Gas</h5>

      <div class="input-search-container mb-3">
        <div class="container-search-icon" >
          <i v-if="gasSelected.length == 0" class="fa-solid fa-magnifying-glass"></i>
        </div>
        <vSelect
          :class="`w-100 fs-md ${gasSelected.length == 0 ? 'ps-5' : ''}`"
          label="name"
          :clearable="false"
          :value="gasSelected[0]"
          :options="energySuppliers"
          :placeholder="`Search gas provider`"
          :multiple="false"
          @option:selected="(ev) => selectChange(ev, 'gasSelected', 'selectedGasSupplier')"
        >
          <template slot="option" slot-scope="option">
            <div class="d-flex align-items-center py-1">
              <img
                :src="`${urlBucket}/${option.icon}`"
                width="30px"
                class="me-3"
                style="display: none"
                @load="
                  (e) => {
                    e.target.style.display = 'unset';
                    hideSpinner(option.name);
                  }
                "
              />
              <div :id="option.name" class="text-center me-3">
                <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
              </div>
              <p class="mb-0 fs-md">{{ option.name }}</p>
            </div>
          </template>

          <template slot="selected-option" slot-scope="option">
            <div class="d-flex align-items-center">
              <img
                :src="`${urlBucket}/${option.icon}`"
                class="ms-3 me-2"
                style="max-height: 40px"
              />
              <span>{{ option.name }}</span>
            </div>
          </template>
        </vSelect>
      </div>

      <div class="chip-list-wrapper mb-2" v-if="gasSelected.length > 0">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in gasSelected"
          :key="index"
          @click="deleteGas(index)"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <!-- Energy-->

    <!--  v-if="oldCouncil != newCouncil" -->
    <div class="mb-5">
      <h5 class="fs-la mb-2">
        Your new property <i class="fa-solid fa-circle-info" @click="openInfoTooltip()"></i>
      </h5>
      <p class="fs-sm">Setting up utilities at {{ moveToAddress }}</p>

      <CheckboxWithDropdown
        v-if="this.informWaterSewerage"
        :logo="`icons/${newWater}.png`"
        :informName="'informNewWater'"
        :companyName="'newWater'"
        :is-checked="informNewWater"
        :value="newWater"
        :options="waterCompanies"
        :imgOnError="'form/assets/water.svg'"
        :widthLogo="'70px'"
      />

      <CheckboxWithDropdown
        v-if="informCouncilTax"
        :logo="`icons/councils/${newCouncil}.png`"
        :informName="'informNewCouncil'"
        :companyName="'newCouncil'"
        :is-checked="informNewCouncil"
        :value="newCouncil"
        :options="councils"
        :imgOnError="'form/assets/hmrc.svg'"
        :widthLogo="'70px'"
      />

      <!-- <Checkbox
        v-if="this.informWaterSewerage"
        :logo="`icons/${newWater}.png`"
        :description="newWater"
        :informName="'informNewWater'"
        :value="informNewWater"
        :subdescription="false"
        :imgOnError="'form/assets/water.svg'"
        :widthLogo="'70px'"
      />
      <Checkbox
        v-if="informCouncilTax"
        :logo="`icons/councils/${newCouncil}.png`"
        :description="newCouncil"
        :informName="'informNewCouncil'"
        :value="informNewCouncil"
        :subdescription="false"
        :imgOnError="'form/assets/hmrc.svg'"
        :widthLogo="'70px'"
      /> -->
    </div>

    <!--Will you be a single occupant  -->
    <div class="mb-4" v-if="informCouncilTax">
      <h5 class="fs-la">Will you be a single occupant?</h5>
      <div class="d-flex mb-2">
        <div
          :class="`box-answers fs-md ${singleOccupant === true ? 'checked' : ''}`"
          @click="updateValue('singleOccupant', true)"
        >
          Yes
        </div>
        <div
          :class="`box-answers fs-md ${singleOccupant === false ? 'checked' : ''} ms-2`"
          @click="updateValue('singleOccupant', false)"
        >
          No
        </div>
      </div>
      <p v-if="errors.singleOccupant" style="color: #c64a45">* This field is required</p>
    </div>

    <!-- Label Pages -->
    <div class="mt-5">
      <div
        v-if="informCouncilTax && singleOccupant"
        class="label-pages mb-3 d-flex p-2 align-items-center"
      >
        <img :src="require('@/assets/check-y.png')" width="30px" alt="" class="ms-2 me-2" />
        <p class="ms-1 fs-sm mb-0">
          Excellent, we'll help you get setup with your 25% Council Tax discount
        </p>
      </div>

      <div class="label-pages d-flex p-2 px-3 align-items-center">
        <img :src="require('@/assets/light.png')" width="30px" class="ms-2 me-2" alt="" />
        <p class="ms-1 fs-sm mb-0">Only select the utilities that you're responsible for</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Checkbox from "../components/Checkbox.vue";
import CheckboxWithDropdown from "../components/CheckboxWithDropdown.vue";
import { councils, waterCompanies, energySuppliers } from "../data/lists.js";
import { navigateTo } from "../router/index.js";
import vSelect from "vue-select";
import { setRoute } from "../router/setRoute.js";

// @ is an alias to /src

export default {
  name: "PageCouncil",
  components: { Checkbox, vSelect, CheckboxWithDropdown },
  data() {
    return {
      energySuppliers,
      waterCompanies,
      councils,
      electricitySelected: [],
      gasSelected: [],
      errors: {
        singleOccupant: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    openInfoTooltip() {
      this.$store.commit("setField", ["currentTooltip", "ActionSheetEnergy"]);
      const pushbar = new Pushbar({
        blur: true,
        overlay: true,
      });
      pushbar.open("action-sheet");
    },
    validateInputs() {
      if (this.informCouncilTax && this.singleOccupant === "") {
        this.errors.singleOccupant = true;
      } else {
        this.errors.singleOccupant = false;
      }
    },
    updateValue(itemName, value) {
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    selectService(informName, value) {
      this.$store.commit("formStore/setField", [`${informName}`, !value]);
    },
    selectChange(value, supplierSelected, supplierForm) {
      // console.log("selectChange", value);
      this[`${supplierSelected}`] = [];

      if (!this[`${supplierSelected}`].includes(value)) {
        this[`${supplierSelected}`].push(value);
        this.$store.commit("formStore/setField", [supplierForm, [value]]);
      }
    },
    deleteElectricity(index) {
      this.electricitySelected.splice(index, 1);
      this.$store.commit("formStore/setField", [`selectedEnergySupplier`, []]);
    },
    deleteGas(index) {
      this.gasSelected.splice(index, 1);
      this.$store.commit("formStore/setField", [`selectedGasSupplier`, []]);
    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "Your utilities"]);

    this.electricitySelected = this.selectedEnergySupplier;
    this.gasSelected = this.selectedGasSupplier;

    // const setWaterPath = (water) => {
    //   let path = "";

    //   if (water == "Anglian Water") path = "anglian";
    //   if (water == "Northern Ireland Water") path = "irishWater";
    //   if (water == "Dwr Cymru Welsh Water") path = "welshWater";
    //   if (water == "Northumbrian Water") path = "nwg";
    //   if (water == "Severn Trent") path = "severnTrent";
    //   if (water == "South West Water") path = "southWWater";
    //   if (water == "Southern Water") path = "southernW";
    //   if (water == "Thames Water") path = "ThamesW";
    //   if (water == "United Utilities") path = "unitedUtilities";
    //   if (water == "Wessex Water") path = "wessexW";
    //   if (water == "Yorkshire Water") path = "yorkshiteW";
    //   if (water == "Affinity Water") path = "affinityW";
    //   if (water == "Bournemouth Water") path = "BournemouthW";
    //   if (water == "Bristol Water") path = "bristolW";
    //   if (water == "Cambridge Water") path = "cambridgeW";
    //   if (water == "Essex & Suffolk Water") path = "essexSW";
    //   if (water == "Portsmouth Water") path = "portsmouthW";
    //   if (water == "South East Water") path = "southeastW";
    //   if (water == "South Staffordshire Water") path = "southStaffsW";
    //   if (water == "SES Water") path = "SESW";

    //   return path;
    // };

    const validatePage = () => {
      // let route = "/about-you";
      // const setRoute = () => {
      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }
      // };
      // setRoute();

      this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(setRoute(this.state));
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },
  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "moveFromAddress",
      "moveToAddress",
      "completeMoveFromAddress",
      "completeMoveToAddress",
      "informNewCouncil",
      "informNewWater",
      "informOldCouncil",
      "informOldWater",
      "informMobile",
      "oldCouncil",
      "newCouncil",
      "oldWater",
      "newWater",
      "informLoyalty",
      "informCharities",
      "informGyms",
      "informBanks",
      "informCouncilTax",
      "informWaterSewerage",
      "informEnergy",
      "selectedEnergySupplier",
      "selectedGasSupplier",
      "singleOccupant",
      "informOpticians",
    ]),
    ...mapGetters("formStore", ["state"]),
  },
  watch: {
  },
};
</script>
<style lang="scss">
.page-council {
  .icon-search {
    color: #8a8a8a;
    float: left;
    margin-left: 15px;
    margin-top: -65px;
    position: relative;
    z-index: 2;
    font-size: 23px;
  }

  .v-select {
    color: black;
    font-weight: 600;

    .vs__actions {
      margin-right: 5px;
    }

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }

    .vs__search::placeholder {
      color: #8a8a8a;
    }
    .vs__dropdown-menu {
      p {
        font-weight: 200;
      }
    }

    .enter-manually {
      cursor: pointer;
      font-size: 12px;
      color: #8a8a8a;
      text-decoration: underline;
    }
  }

  .input-search-container {
    .v-select {
      .vs__dropdown-toggle {
        padding-left: 0px !important;
      }
    }
  }

  .chip-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .chip {
      display: flex;
      align-items: center;
      padding: 7px;
      height: 30px;
      border-radius: 25px;
      background-color: #f1f1f1;

      p {
        color: black;
        font-size: 14px;
      }

      .fa-circle-check {
        color: #1e9d68;
        font-size: 16px;
      }
    }
  }

  .box-answers {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 14px 34px;
    text-align: center;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989;
    background-color: #1e9d68;
    color: white;
  }
}
</style>
